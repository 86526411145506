package showcases

import dev.petuska.katalog.runtime.domain.Showcase

public val MDCRippleKatalogShowcase: Showcase = Showcase(
      id = "MDCRipple",
      title = "MDCRipple",
      description = null,
      location = "sandbox/src/jsMain/showcases/MDCRipple.kt#L22",
      content = {
        MDCRipple()
      },
    )
