package showcases

import dev.petuska.katalog.runtime.domain.Showcase

public val MDCTopAppBarKatalogShowcase: Showcase = Showcase(
      id = "MDCTopAppBar",
      title = "MDCTopAppBar",
      description = null,
      location = "sandbox/src/jsMain/showcases/MDCTopAppBar.kt#L27",
      content = {
        MDCTopAppBar()
      },
    )
