package dev.petuska.katalog

import dev.petuska.katalog.runtime.renderKatalog
import sandbox.config
import showcases.MDCBannerKatalogShowcase
import showcases.MDCButtonKatalogShowcase
import showcases.MDCCardKatalogShowcase
import showcases.MDCCheckboxKatalogShowcase
import showcases.MDCChipsKatalogShowcase
import showcases.MDCCircularProgressKatalogShowcase
import showcases.MDCDataTableKatalogShowcase
import showcases.MDCDialogKatalogShowcase
import showcases.MDCDrawerKatalogShowcase
import showcases.MDCElevationKatalogShowcase
import showcases.MDCFabKatalogShowcase
import showcases.MDCFloatingLabelKatalogShowcase
import showcases.MDCFormFieldKatalogShowcase
import showcases.MDCIconButtonKatalogShowcase
import showcases.MDCIconKatalogShowcase
import showcases.MDCImageListKatalogShowcase
import showcases.MDCLineRippleKatalogShowcase
import showcases.MDCLinearProgressKatalogShowcase
import showcases.MDCListKatalogShowcase
import showcases.MDCMenuKatalogShowcase
import showcases.MDCMenuSurfaceKatalogShowcase
import showcases.MDCNotchedOutlineKatalogShowcase
import showcases.MDCRadioKatalogShowcase
import showcases.MDCRippleKatalogShowcase
import showcases.MDCSegmentedButtonKatalogShowcase
import showcases.MDCSelectKatalogShowcase
import showcases.MDCSliderKatalogShowcase
import showcases.MDCSnackbarKatalogShowcase
import showcases.MDCSwitchKatalogShowcase
import showcases.MDCTabBarKatalogShowcase
import showcases.MDCTextFieldKatalogShowcase
import showcases.MDCTooltipKatalogShowcase
import showcases.MDCTopAppBarKatalogShowcase
import showcases.MDCTouchTargetKatalogShowcase
import showcases.MDCTypographyKatalogShowcase

public fun main() {
  renderKatalog(showcases = listOf(MDCBannerKatalogShowcase, MDCButtonKatalogShowcase,
      MDCCardKatalogShowcase, MDCCheckboxKatalogShowcase, MDCChipsKatalogShowcase,
      MDCCircularProgressKatalogShowcase, MDCDataTableKatalogShowcase, MDCDialogKatalogShowcase,
      MDCDrawerKatalogShowcase, MDCElevationKatalogShowcase, MDCFabKatalogShowcase,
      MDCFloatingLabelKatalogShowcase, MDCFormFieldKatalogShowcase, MDCIconKatalogShowcase,
      MDCIconButtonKatalogShowcase, MDCImageListKatalogShowcase, MDCLineRippleKatalogShowcase,
      MDCLinearProgressKatalogShowcase, MDCListKatalogShowcase, MDCMenuKatalogShowcase,
      MDCMenuSurfaceKatalogShowcase, MDCNotchedOutlineKatalogShowcase, MDCRadioKatalogShowcase,
      MDCRippleKatalogShowcase, MDCSegmentedButtonKatalogShowcase, MDCSelectKatalogShowcase,
      MDCSliderKatalogShowcase, MDCSnackbarKatalogShowcase, MDCSwitchKatalogShowcase,
      MDCTabBarKatalogShowcase, MDCTextFieldKatalogShowcase, MDCTooltipKatalogShowcase,
      MDCTopAppBarKatalogShowcase, MDCTouchTargetKatalogShowcase, MDCTypographyKatalogShowcase),
      config = { config()
   })
}
