package showcases

import dev.petuska.katalog.runtime.domain.Showcase

public val MDCSwitchKatalogShowcase: Showcase = Showcase(
      id = "MDCSwitch",
      title = "MDCSwitch",
      description = null,
      location = "sandbox/src/jsMain/showcases/MDCSwitch.kt#L23",
      content = {
        MDCSwitch()
      },
    )
