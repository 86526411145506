package showcases

import dev.petuska.katalog.runtime.domain.Showcase

public val MDCCheckboxKatalogShowcase: Showcase = Showcase(
      id = "MDCCheckbox",
      title = "MDCCheckbox",
      description = null,
      location = "sandbox/src/jsMain/showcases/MDCCheckbox.kt#L20",
      content = {
        MDCCheckbox()
      },
    )
