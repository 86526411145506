package showcases

import dev.petuska.katalog.runtime.domain.Showcase

public val MDCElevationKatalogShowcase: Showcase = Showcase(
      id = "MDCElevation",
      title = "MDCElevation",
      description = null,
      location = "sandbox/src/jsMain/showcases/MDCElevation.kt#L17",
      content = {
        MDCElevation()
      },
    )
