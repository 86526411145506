package showcases

import dev.petuska.katalog.runtime.domain.Showcase

public val MDCLineRippleKatalogShowcase: Showcase = Showcase(
      id = "MDCLineRipple",
      title = "MDCLineRipple",
      description = null,
      location = "sandbox/src/jsMain/showcases/MDCLineRipple.kt#L18",
      content = {
        MDCLineRipple()
      },
    )
