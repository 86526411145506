package showcases

import dev.petuska.katalog.runtime.domain.Showcase

public val MDCTypographyKatalogShowcase: Showcase = Showcase(
      id = "MDCTypography",
      title = "MDCTypography",
      description = null,
      location = "sandbox/src/jsMain/showcases/MDCTypography.kt#L15",
      content = {
        MDCTypography()
      },
    )
