package showcases

import dev.petuska.katalog.runtime.domain.Showcase

public val MDCDialogKatalogShowcase: Showcase = Showcase(
      id = "MDCDialog",
      title = "MDCDialog",
      description = null,
      location = "sandbox/src/jsMain/showcases/MDCDialog.kt#L40",
      content = {
        MDCDialog()
      },
    )
