package showcases

import dev.petuska.katalog.runtime.domain.Showcase

public val MDCSnackbarKatalogShowcase: Showcase = Showcase(
      id = "MDCSnackbar",
      title = "MDCSnackbar",
      description = null,
      location = "sandbox/src/jsMain/showcases/MDCSnackbar.kt#L27",
      content = {
        MDCSnackbar()
      },
    )
