package showcases

import dev.petuska.katalog.runtime.domain.Showcase

public val MDCTextFieldKatalogShowcase: Showcase = Showcase(
      id = "MDCTextField",
      title = "MDCTextField",
      description = null,
      location = "sandbox/src/jsMain/showcases/MDCTextField.kt#L41",
      content = {
        MDCTextField()
      },
    )
