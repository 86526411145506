package showcases

import dev.petuska.katalog.runtime.domain.Showcase

public val MDCCircularProgressKatalogShowcase: Showcase = Showcase(
      id = "MDCCircularProgress",
      title = "MDCCircularProgress",
      description = null,
      location = "sandbox/src/jsMain/showcases/MDCCircularProgress.kt#L21",
      content = {
        MDCCircularProgress()
      },
    )
