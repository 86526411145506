package showcases

import dev.petuska.katalog.runtime.domain.Showcase

public val MDCBannerKatalogShowcase: Showcase = Showcase(
      id = "MDCBanner",
      title = "MDCBanner",
      description = null,
      location = "sandbox/src/jsMain/showcases/MDCBanner.kt#L23",
      content = {
        MDCBanner()
      },
    )
