package showcases

import dev.petuska.katalog.runtime.domain.Showcase

public val MDCDrawerKatalogShowcase: Showcase = Showcase(
      id = "MDCDrawer",
      title = "MDCDrawer",
      description = null,
      location = "sandbox/src/jsMain/showcases/MDCDrawer.kt#L27",
      content = {
        MDCDrawer()
      },
    )
