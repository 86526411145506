package showcases

import dev.petuska.katalog.runtime.domain.Showcase

public val MDCTabBarKatalogShowcase: Showcase = Showcase(
      id = "MDCTabBar",
      title = "MDCTabBar",
      description = null,
      location = "sandbox/src/jsMain/showcases/MDCTabBar.kt#L36",
      content = {
        MDCTabBar()
      },
    )
