package showcases

import dev.petuska.katalog.runtime.domain.Showcase

public val MDCLinearProgressKatalogShowcase: Showcase = Showcase(
      id = "MDCLinearProgress",
      title = "MDCLinearProgress",
      description = null,
      location = "sandbox/src/jsMain/showcases/MDCLinearProgress.kt#L24",
      content = {
        MDCLinearProgress()
      },
    )
