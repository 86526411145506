package showcases

import dev.petuska.katalog.runtime.domain.Showcase

public val MDCDataTableKatalogShowcase: Showcase = Showcase(
      id = "MDCDataTable",
      title = "MDCDataTable",
      description = null,
      location = "sandbox/src/jsMain/showcases/MDCDataTable.kt#L36",
      content = {
        MDCDataTable()
      },
    )
