package showcases

import dev.petuska.katalog.runtime.domain.Showcase

public val MDCTouchTargetKatalogShowcase: Showcase = Showcase(
      id = "MDCTouchTarget",
      title = "MDCTouchTarget",
      description = null,
      location = "sandbox/src/jsMain/showcases/MDCTouchTarget.kt#L20",
      content = {
        MDCTouchTarget()
      },
    )
