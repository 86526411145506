package showcases

import dev.petuska.katalog.runtime.domain.Showcase

public val MDCFabKatalogShowcase: Showcase = Showcase(
      id = "MDCFab",
      title = "MDCFab",
      description = null,
      location = "sandbox/src/jsMain/showcases/MDCFab.kt#L28",
      content = {
        MDCFab()
      },
    )
