package showcases

import dev.petuska.katalog.runtime.domain.Showcase

public val MDCIconKatalogShowcase: Showcase = Showcase(
      id = "MDCIcon",
      title = "MDCIcon",
      description = null,
      location = "sandbox/src/jsMain/showcases/MDCIcon.kt#L26",
      content = {
        MDCIcon()
      },
    )
