package showcases

import dev.petuska.katalog.runtime.domain.Showcase

public val MDCChipsKatalogShowcase: Showcase = Showcase(
      id = "MDCChips",
      title = "MDCChips",
      description = null,
      location = "sandbox/src/jsMain/showcases/MDCChips.kt#L35",
      content = {
        MDCChips()
      },
    )
