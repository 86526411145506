package showcases

import dev.petuska.katalog.runtime.domain.Showcase

public val MDCSelectKatalogShowcase: Showcase = Showcase(
      id = "MDCSelect",
      title = "MDCSelect",
      description = null,
      location = "sandbox/src/jsMain/showcases/MDCSelect.kt#L41",
      content = {
        MDCSelect()
      },
    )
