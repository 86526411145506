package showcases

import dev.petuska.katalog.runtime.domain.Showcase

public val MDCMenuSurfaceKatalogShowcase: Showcase = Showcase(
      id = "MDCMenuSurface",
      title = "MDCMenuSurface",
      description = null,
      location = "sandbox/src/jsMain/showcases/MDCMenuSurface.kt#L33",
      content = {
        MDCMenuSurface()
      },
    )
