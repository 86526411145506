package showcases

import dev.petuska.katalog.runtime.domain.Showcase

public val MDCCardKatalogShowcase: Showcase = Showcase(
      id = "MDCCard",
      title = "MDCCard",
      description = null,
      location = "sandbox/src/jsMain/showcases/MDCCard.kt#L33",
      content = {
        MDCCard()
      },
    )
