package showcases

import dev.petuska.katalog.runtime.domain.Showcase

public val MDCFormFieldKatalogShowcase: Showcase = Showcase(
      id = "MDCFormField",
      title = "MDCFormField",
      description = null,
      location = "sandbox/src/jsMain/showcases/MDCFormField.kt#L25",
      content = {
        MDCFormField()
      },
    )
