package showcases

import dev.petuska.katalog.runtime.domain.Showcase

public val MDCSegmentedButtonKatalogShowcase: Showcase = Showcase(
      id = "MDCSegmentedButton",
      title = "MDCSegmentedButton",
      description = null,
      location = "sandbox/src/jsMain/showcases/MDCSegmentedButton.kt#L24",
      content = {
        MDCSegmentedButton()
      },
    )
