package showcases

import dev.petuska.katalog.runtime.domain.Showcase

public val MDCSliderKatalogShowcase: Showcase = Showcase(
      id = "MDCSlider",
      title = "MDCSlider",
      description = null,
      location = "sandbox/src/jsMain/showcases/MDCSlider.kt#L60",
      content = {
        MDCSlider()
      },
    )
