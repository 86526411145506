package showcases

import dev.petuska.katalog.runtime.domain.Showcase

public val MDCRadioKatalogShowcase: Showcase = Showcase(
      id = "MDCRadio",
      title = "MDCRadio",
      description = null,
      location = "sandbox/src/jsMain/showcases/MDCRadio.kt#L20",
      content = {
        MDCRadio()
      },
    )
