package showcases

import dev.petuska.katalog.runtime.domain.Showcase

public val MDCListKatalogShowcase: Showcase = Showcase(
      id = "MDCList",
      title = "MDCList",
      description = null,
      location = "sandbox/src/jsMain/showcases/MDCList.kt#L30",
      content = {
        MDCList()
      },
    )
