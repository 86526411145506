package showcases

import dev.petuska.katalog.runtime.domain.Showcase

public val MDCMenuKatalogShowcase: Showcase = Showcase(
      id = "MDCMenu",
      title = "MDCMenu",
      description = null,
      location = "sandbox/src/jsMain/showcases/MDCMenu.kt#L45",
      content = {
        MDCMenu()
      },
    )
