package showcases

import dev.petuska.katalog.runtime.domain.Showcase

public val MDCNotchedOutlineKatalogShowcase: Showcase = Showcase(
      id = "MDCNotchedOutline",
      title = "MDCNotchedOutline",
      description = null,
      location = "sandbox/src/jsMain/showcases/MDCNotchedOutline.kt#L27",
      content = {
        MDCNotchedOutline()
      },
    )
