package showcases

import dev.petuska.katalog.runtime.domain.Showcase

public val MDCFloatingLabelKatalogShowcase: Showcase = Showcase(
      id = "MDCFloatingLabel",
      title = "MDCFloatingLabel",
      description = null,
      location = "sandbox/src/jsMain/showcases/MDCFloatingLabel.kt#L22",
      content = {
        MDCFloatingLabel()
      },
    )
