package showcases

import dev.petuska.katalog.runtime.domain.Showcase

public val MDCTooltipKatalogShowcase: Showcase = Showcase(
      id = "MDCTooltip",
      title = "MDCTooltip",
      description = null,
      location = "sandbox/src/jsMain/showcases/MDCTooltip.kt#L28",
      content = {
        MDCTooltip()
      },
    )
