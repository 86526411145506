package showcases

import dev.petuska.katalog.runtime.domain.Showcase

public val MDCIconButtonKatalogShowcase: Showcase = Showcase(
      id = "MDCIconButton",
      title = "MDCIconButton",
      description = null,
      location = "sandbox/src/jsMain/showcases/MDCIconButton.kt#L29",
      content = {
        MDCIconButton()
      },
    )
