package showcases

import dev.petuska.katalog.runtime.domain.Showcase

public val MDCButtonKatalogShowcase: Showcase = Showcase(
      id = "MDCButton",
      title = "MDCButton",
      description = null,
      location = "sandbox/src/jsMain/showcases/MDCButton.kt#L28",
      content = {
        MDCButton()
      },
    )
